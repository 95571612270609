/* import __COLOCATED_TEMPLATE__ from './forwarding.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import { isValidEmail as isValidEmailAddress } from 'embercom/lib/email';
import storage from 'embercom/vendor/intercom/storage';
import ajax from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { captureException } from 'embercom/lib/sentry';

const MANUALLY_ADDED = 0;
const LOCALSTORAGE_EMAIL_KEY = 'forward-emails-component-email';

interface Args {}

export default class Forwarding extends Component<Args> {
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare realTimeEventService: $TSFixMe;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare paywallService: any;
  @service declare purchaseAnalyticsService: any;

  @tracked addCompanyEmailAddressFormExpanded = false;
  @tracked email = '';
  @tracked emailSet = false;
  @tracked verification: boolean | null = null;
  @tracked retryAttempts = 0;
  @tracked newCompanyEmailAddress = '';
  @tracked forwardMailSetupAttempt: $TSFixMe | null = null;
  @tracked openSectionId = 'test';
  maxRetryAttempts = 20;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.restoreEmailFromStorage();
    this.realTimeEventService.on('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
  }

  willDestroy() {
    this.realTimeEventService.off('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
    super.willDestroy();
  }

  get app() {
    return this.appService.app;
  }

  get companyEmailAddresses() {
    return this.app.companyEmailAddresses;
  }

  get hasCompanyEmailAddresses() {
    return this.companyEmailAddresses.length > 0;
  }

  get isValidEmail() {
    return isValidEmailAddress(this.email);
  }

  get hasForwardingEmail() {
    return this.email !== '';
  }

  get inboxAddress() {
    return this.app.inbox_email;
  }

  get analyticsMetadata() {
    return {
      place: 'settings',
      owner: 'team-product-exploration',
      object: 'email_forwarding_steps',
    };
  }

  get isOnEmailForwardingExperiment() {
    return this.app.isOnEmailForwardingExperiment;
  }

  duplicateEmailAddress(emailAddress: string): boolean {
    let companyEmailAddresses = this.companyEmailAddresses;
    let duplicateFound = false;
    companyEmailAddresses.forEach(function (item: $TSFixMe) {
      if (item.get('email_address') === emailAddress) {
        duplicateFound = true;
      }
    });
    return duplicateFound;
  }

  @action
  expandAddCompanyEmailAddressForm() {
    this.addCompanyEmailAddressFormExpanded = true;
  }

  @action
  collapseAddCompanyEmailAddressForm() {
    this.addCompanyEmailAddressFormExpanded = false;
  }

  @action
  setEmail() {
    if (this.isValidEmail) {
      storage.set(LOCALSTORAGE_EMAIL_KEY, this.email);
      this.emailSet = true;
      this._trackAnalyticsEvent({
        action: 'clicked',
        object: 'set_email',
      });
    }
  }

  @action
  unsetEmail() {
    storage.remove(LOCALSTORAGE_EMAIL_KEY);
    this.verification = null;
    this.emailSet = false;
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'unset_email',
    });
  }

  @action trackOpenSection(sectionName: string) {
    this.trackAnalyticsEvent('open', `accordion-section_${sectionName}`);
  }

  @action trackCloseSection(sectionName: string) {
    this.trackAnalyticsEvent('close', `accordion-section_${sectionName}`);
  }

  trackAnalyticsEvent(action: string, object: string) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      context: 'email-forwarding-experiment',
      place: 'settings_channels_email',
      object,
    });
  }

  restoreEmailFromStorage() {
    if (this.email !== '') {
      return;
    }
    let email = storage.get(LOCALSTORAGE_EMAIL_KEY);
    if (!email) {
      return;
    }
    this.email = email;
    this.emailSet = true;
  }

  async _createSetupAttempt() {
    let forwardMailSetupAttempt = await this.store
      .createRecord('forward-mail-setup-attempt', {
        appId: this.app.id,
        forwardingEmailAddress: this.email,
      })
      .save();

    this.forwardMailSetupAttempt = forwardMailSetupAttempt;
  }

  async _sendForwardingEmail() {
    await ajax({
      url: '/ember/forward_emails',
      data: JSON.stringify({
        app_id: this.app.id,
        email: this.email,
      }),
      type: 'POST',
    });
  }

  async _verifyEmailForwarding() {
    return await ajax({
      url: '/ember/forward_mail_setup_attempts/verify',
      type: 'GET',
      data: {
        app_id: this.app.id,
        forwarding_email_address: this.email,
      },
    });
  }

  _trackAnalyticsEvent(options: { action?: string; object?: string; context?: string }) {
    this.intercomEventService.trackAnalyticsEvent({
      email: this.email,
      ...options,
      ...this.analyticsMetadata,
    });
  }

  _updateVerification(status: boolean) {
    this.verification = status;
  }

  _handleForwardMailVerifiedMessage() {
    this._updateVerification(true);
    if (this.guideLibraryService.canUseGuideLibraryService) {
      this.guideLibraryService.markStepCompleted(
        'guide_library_foundational_steps_setup_omnichannel_v2',
      );
    }
    this._trackAnalyticsEvent({
      action: 'completed',
      object: 'send_test_email',
      context: 'nexus_event',
    });
  }

  @dropTask
  *addNewCompanyEmailAddress(emailAddress: string) {
    if (!isValidEmailAddress(emailAddress)) {
      this.notificationsService.notifyWarning(
        this.intl.t('apps.app.settings.email-forwarding.enter-valid-email'),
      );
    } else if (this.duplicateEmailAddress(emailAddress)) {
      this.notificationsService.notifyWarning(
        this.intl.t('apps.app.settings.email-forwarding.email-already-added'),
      );
    } else {
      let companyEmailAddress = this.store.createRecord('company-email-address', {
        email_address: emailAddress,
        source: MANUALLY_ADDED,
      });
      this.collapseAddCompanyEmailAddressForm();
      try {
        yield companyEmailAddress.save();
        this.newCompanyEmailAddress = '';
        this.notificationsService.notifyConfirmation(
          this.intl.t('apps.app.settings.email-forwarding.registered'),
        );
      } catch (e) {
        this.notificationsService.notifyError(
          this.intl.t('apps.app.settings.email-forwarding.email-not-saved'),
        );
        this.expandAddCompanyEmailAddressForm();
        this.store.deleteRecord(companyEmailAddress);
      }
    }
  }

  @dropTask
  *verifyEmailForwarding(): TaskGenerator<void> {
    this._trackAnalyticsEvent({ action: 'clicked', object: 'send_test_email' });
    let verification;
    yield this._createSetupAttempt();
    yield this._sendForwardingEmail();

    this.retryAttempts = 0;
    verification = yield this._verifyEmailForwarding();
    try {
      while (!verification && this.retryAttempts < this.maxRetryAttempts) {
        yield timeout(ENV.APP._1000MS); // wait for nexus event
        this.retryAttempts += 1;
        verification = yield this._verifyEmailForwarding();
      }
    } catch (e) {
      captureException(e, {
        fingerprint: ['forward-emails', 'verify_email_forwarding'],
      });
      this.retryAttempts = 0;
    }

    if (!this.verification) {
      this._updateVerification(verification);
      this._trackAnalyticsEvent({
        action: verification ? 'completed' : 'failed',
        context: 'verify_endpoint',
        object: 'send_test_email',
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Forwarding': typeof Forwarding;
  }
}
