/* import __COLOCATED_TEMPLATE__ from './tickets.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  model: any;
  tab: string;
  changeTab: (tab: string) => void;
}

export default class Tickets extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked selectedHelpCenter: HelpCenterSite | null = null;
  @tracked showTicketTypeSideDrawer = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (!this.canSeeIASettingsGA && this.selectedTab === 'tickets-portal') {
      args.changeTab('ticket-types');
    }
  }

  @action
  changeTab(newTab: string) {
    if (newTab === 'ticket-states') {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'ticket_states',
        place: 'ticket_settings_page',
      });
    }
    this.args.changeTab(newTab);
  }

  get selectedTab() {
    return this.args.tab;
  }

  get canSeeIASettingsGA() {
    return this.appService.app.canSeeIASettingsGA;
  }

  @action
  onSwitchHelpCenter(helpCenter: HelpCenterSite) {
    this.selectedHelpCenter = helpCenter;
  }

  @action
  async saveHelpCenterSettings() {
    try {
      await this.selectedHelpCenter?.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.helpdesk.tickets.tickets-portal.settings.portal-update-success'),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('new-settings.helpdesk.tickets.tickets-portal.settings.portal-update-error'),
      );
    }
  }

  @action
  createNewTicketType() {
    this.showTicketTypeSideDrawer = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'create-ticket-type',
      object: `create-ticket-type-cta`,
      section: 'settings.helpdesk.tickets.wrapper',
      place: 'settings.helpdesk.tickets',
    });
  }

  @action onTicketTypeSideDrawerClose() {
    this.showTicketTypeSideDrawer = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::Tickets': typeof Tickets;
  }
}
