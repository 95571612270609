/* import __COLOCATED_TEMPLATE__ from './customize-fin-with-workflows-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface Args {
  context: 'not-pay-for-workflows' | 'paying-for-workflows';
  addWorkflow?: () => void;
}

export default class CustomizeFinWithWorkflowsBanner extends Component<Args> {
  @action
  addWorkflow() {
    this.args.addWorkflow?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::CustomizeFinWithWorkflowsBanner': typeof CustomizeFinWithWorkflowsBanner;
  }
}
