/* import __COLOCATED_TEMPLATE__ from './conversation-extraction.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';
import { captureException } from 'embercom/lib/sentry';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { post } from 'embercom/lib/ajax';
import { localCopy } from 'tracked-toolbox';
import { type ConversationContentAvailabilitySetting } from '../content-sources';

interface Args {
  adminsLabel: string;
  admins: {
    text: string;
    value: number;
    isSelected: boolean;
  }[];
  adminsChanged: (adminIDs: number[]) => void;
  submitAdmins: () => Promise<void>;
  clearAllAdmins: () => void;
  teamIDs: number[];
  onEnableImmediatelyAvailableToFin: () => Promise<void>;
  onDisableImmediatelyAvailableToFin: () => Promise<void>;
  htmlConversationContentAvailability: ConversationContentAvailabilitySetting;
  conversationContentEnabled: boolean;
  enableConversationContent: Function;
  disableConversationContent: Function;
}

export default class ConversationExtraction extends Component<Args> {
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare appService: any;
  @service intercomEventService: any;

  @localCopy('args.teamIDs')
  declare teamIDs: number[];

  get teamsLabel() {
    if (this.teamIDs.length > 0) {
      return this.intl.t(
        'operator.fin.setup.content-sources.conversation-content.team-settings.label.selected',
        { num: this.teamIDs.length },
      );
    } else {
      return this.intl.t(
        'operator.fin.setup.content-sources.conversation-content.team-settings.label.blank',
      );
    }
  }

  get teams() {
    let selectedTeams = this.teamIDs;

    let teams = this.appService.app.assignableTeams;

    return teams.map((team: any) => ({
      text: team.name,
      value: Number(team.id),
      isSelected: selectedTeams.includes(Number(team.id)),
    }));
  }

  @action teamsChanged(teamIDs: number[]) {
    this.teamIDs = teamIDs.map((id) => Number(id));
  }

  @action clearAllTeams() {
    this.teamIDs = [];
  }

  @action async submitTeams() {
    try {
      await post('/ember/inbox/fin_conversation_content/update_teams', {
        app_id: this.appService.app.id,
        team_ids: this.teamIDs,
      });
      this.intercomEventService.trackAnalyticsEvent({
        action: 'update_teams',
        object: 'inbox_fin_question_answers',
        section: 'fin_setup',
        team_ids: this.teamIDs,
      });
    } catch (e) {
      this.teamIDs = this.args.teamIDs;
      captureException(e);
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.content-sources.conversation-content.update-teams-failed'),
      );
    }
  }

  @action async toggleConversationContent() {
    if (this.args.conversationContentEnabled) {
      await this.args.disableConversationContent();
    } else {
      await this.args.enableConversationContent();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::ContentSources::ConversationExtraction': typeof ConversationExtraction;
  }
}
