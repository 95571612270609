/* import __COLOCATED_TEMPLATE__ from './setup-and-go-live.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { action } from '@ember/object';
import type Template from 'embercom/models/template';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';

interface Arguments {
  hasFinWorkflows: boolean;
  liveFinWorkflowsCount: number;
  hasContentReadyForFin: boolean;
  showConfetti: () => void;
  reloadFinWorkflows: () => void;
  reloadSetupRuleset: (setupType: ChannelType) => void;
  openTemplateModal: (template?: Template, showSimilarTemplates?: boolean) => void;
  previewConfiguration: $TSFixMe;
  finSetupRuleset: $TSFixMe;
  emailSetupRuleset: $TSFixMe;
  channelType: ChannelType;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Arguments;
}

type FinChannel =
  | 'web'
  | 'ios'
  | 'android'
  | 'facebook'
  | 'whatsapp'
  | 'instagram'
  | 'sms'
  | 'email';

const SORT_BY = 'updated_at';
const SORT_DIRECTION_ASC = 'desc';
const EMAIL_CHANNEL = 'email';
const FIN_CHAT_CHANNELS = ['web', 'ios', 'android', 'facebook', 'whatsapp', 'instagram', 'sms'];
export default class SetupAndGoLive extends Component<Signature> {
  @service declare appService: any;
  @service declare outboundHomeService: any;

  @tracked workflows: any[] = [];
  @tracked finWorkflowsLastPageHit = false;
  @tracked totalFinWorklowsCount = 0;
  @tracked showNewWorkflowSidedrawer = false;
  @tracked profiles: $TSFixMe[] = [];
  @tracked totalLiveProfilesCount = 0;
  @tracked totalLiveFinProfilesCount = 0;
  @tracked totalPausedProfilesCount = 0;

  channelType: ChannelType = this.args.channelType;
  pageSize = 100;
  currentPage = 0;
  objectTypes = [
    objectTypes.customBot,
    objectTypes.inboundCustomBot,
    objectTypes.buttonCustomBot,
    objectTypes.triggerableCustomBot,
  ];

  constructor(owner: unknown, args: any) {
    super(owner, args);
    if (this.args.hasFinWorkflows) {
      taskFor(this.fetchFinWorkflows).perform();
    }
    taskFor(this.fetchProfiles).perform();
  }

  @task({ restartable: true })
  *fetchProfiles(): TaskGenerator<void> {
    let response: $TSFixMe = yield this.outboundHomeService.contentSearchWithFilters({
      object_types: [objectTypes.resolutionBotBehavior],
      app_id: this.appService.app.id,
    });

    this.profiles = response.contentWrappers || [];

    this.profiles.forEach((profile) => {
      switch (profile.contents.firstObject?.state) {
        case states.live:
          if (
            profile.contents.firstObject.contentData?.use_ai_answers ||
            profile.contents.firstObject.contentData?.use_custom_answers
          ) {
            this.totalLiveFinProfilesCount += 1;
          }
          this.totalLiveProfilesCount += 1;
          break;
        case states.paused:
          this.totalPausedProfilesCount += 1;
          break;
      }
    });
  }

  @action
  setChannelType(channelType: ChannelType) {
    this.channelType = channelType;
  }

  @action
  async reload() {
    this.args.reloadFinWorkflows();
    taskFor(this.fetchFinWorkflows).perform();
  }

  get totalFinWorkflowsPages() {
    return Math.ceil(this.totalFinWorklowsCount / this.pageSize);
  }

  get isLoading() {
    return taskFor(this.fetchFinWorkflows).isRunning || taskFor(this.fetchProfiles).isRunning;
  }

  get hasProfiles() {
    return this.profiles.length > 0;
  }

  get hasLiveOrPausedProfiles() {
    return this.totalLiveProfilesCount > 0 || this.totalPausedProfilesCount > 0;
  }

  get hasFinWorkflows() {
    return this.totalFinWorklowsCount > 0;
  }

  get finEmailWorkflows() {
    return this.workflows.filter((workflow) =>
      workflow.contents.firstObject.contentData.target_channels.includes(EMAIL_CHANNEL),
    );
  }

  get hasFinEmailWorkflows() {
    return this.finEmailWorkflows.length > 0;
  }

  get finChatWorkflows() {
    return this.workflows.filter((workflow) =>
      workflow.contents.firstObject.contentData.target_channels?.some((channel: FinChannel) =>
        FIN_CHAT_CHANNELS.includes(channel),
      ),
    );
  }

  get hasFinChatWorkflows() {
    return this.finChatWorkflows.length > 0;
  }

  get hasFinWorkflowsForSelectedChannel() {
    if (this.args.channelType === 'email') {
      return this.finEmailWorkflows.length > 0;
    } else if (this.args.channelType === 'chat') {
      return this.finChatWorkflows.length > 0;
    }
    return false;
  }

  get dataForSelectedChannel() {
    if (this.channelType === 'email') {
      return this.finEmailWorkflows;
    } else if (this.channelType === 'chat') {
      return this.finChatWorkflows;
    }
    return [];
  }

  get revelantSetupRuleset() {
    if (this.channelType === 'email') {
      return this.args.emailSetupRuleset;
    }
    return this.args.finSetupRuleset;
  }

  @task({ restartable: true })
  *fetchFinWorkflows(): TaskGenerator<void> {
    let response = yield this.contentSearch(this.currentPage);
    this.currentPage = 0;
    this.workflows = response.contentWrappers;
    this.totalFinWorklowsCount = response.totalCount;
    this.finWorkflowsLastPageHit = response.lastPageHit;
  }

  async contentSearch(pageFrom: number): Promise<object> {
    return this.outboundHomeService.contentSearchWithFilters(this._getSearchParams(pageFrom));
  }

  @task({ drop: true })
  *loadMoreFinWorkflows(): TaskGenerator<void> {
    this.currentPage++;
    let response = yield this.contentSearch(this.currentPage);
    this.workflows = [...this.workflows, ...response.contentWrappers];
    this.totalFinWorklowsCount = response.totalCount;
    this.finWorkflowsLastPageHit = response.lastPageHit;
  }

  _getSearchParams(pageFrom: number) {
    return {
      object_types: this.objectTypes,
      app_id: this.appService.app.id,
      per_page: this.pageSize,
      page_from: pageFrom,
      statistics: [statisticKeys.receipts],
      additionalSearchableData: {
        has_fin: true,
      },
      sort_by: SORT_BY,
      sort_direction: SORT_DIRECTION_ASC,
    };
  }

  @action
  showConfetti() {
    this.args.showConfetti();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::SetupAndGoLive': typeof SetupAndGoLive;
  }
}
