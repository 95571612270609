/* import __COLOCATED_TEMPLATE__ from './new-workflow-sidedrawer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';

const NewWorkflowSidedrawer = templateOnlyComponent<Signature>();
export default NewWorkflowSidedrawer;
interface Signature {
  Args: {
    previewConfiguration: $TSFixMe;
    onClose: () => void;
    isOpen: boolean;
    openTemplateModal: () => void;
    channelType: ChannelType;
  };
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::NewWorkflowSidedrawer': typeof NewWorkflowSidedrawer;
  }
}
