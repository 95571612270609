/* import __COLOCATED_TEMPLATE__ from './workspace-helpcenter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { type TabItem } from '../common/standard-base';
import { action } from '@ember/object';
import EmberObject from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import ajax from 'embercom/lib/ajax';
import ENV from 'embercom/config/environment';
import type HelpCenterSettingNavigationService from 'embercom/services/help-center-setting-navigation-service';
import { type TaskGenerator } from 'ember-concurrency';
import {
  type SectionId,
  type TabId,
} from 'embercom/services/help-center-setting-navigation-service';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import { type UploadHelper } from 'embercom/objects/media/media-helper';
import { type TlsSelectionHelper } from 'embercom/components/articles/site/settings/main';

export class HelpCenterSettingNavigationHelper extends EmberObject {
  navigationService: HelpCenterSettingNavigationService | undefined;

  get currentTabId() {
    return this.navigationService?.currentTabId ?? '';
  }

  get currentSectionId() {
    return this.navigationService?.currentSectionId ?? '';
  }

  navigate(sectionId: SectionId, tabId?: TabId) {
    this.navigationService?.navigate(sectionId, tabId);
  }

  updateContainer(container: HTMLElement) {
    this.navigationService?.updateContainer(container);
  }

  updateLastScrollPosition(scrollPosition: number) {
    this.navigationService?.updateLastScrollPosition(scrollPosition);
  }

  navigateTab(tabId: TabId) {
    this.navigationService?.navigateTab(tabId);
  }
}

interface Signature {
  Args: {
    model: $TSFixMe;
    selectedTab: string;
    setSelectedTab: (value: string) => void;
    uploadHelper: UploadHelper;
    tlsSelectionHelper: TlsSelectionHelper;
    toggleIsSaveRunning: () => void;
  };
  Blocks: {
    default: Array<unknown>;
  };
}

export default class HelpcenterMain extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare appService: any;
  @service declare helpCenterService: any;
  @service declare helpCenterSettingNavigationService: HelpCenterSettingNavigationService;
  @service declare guideLibraryService: GuideLibraryService;

  @tracked uploadHelper: $TSFixMe;
  @tracked selectedAttribute: boolean;
  @tracked showConfirmSetLiveModal = false;
  @tracked showConfirmSetNotLiveModal = false;
  @tracked showGoToHelpCenter;

  erroredDuringUpload = false;
  navigationHelper: HelpCenterSettingNavigationHelper;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.uploadHelper = this.args.uploadHelper;
    this.showGoToHelpCenter = this.site.websiteTurnedOn;
    this.selectedAttribute = this.site.websiteTurnedOn;
    this.navigationHelper = HelpCenterSettingNavigationHelper.create({
      navigationService: this.helpCenterSettingNavigationService,
    });
  }

  @action
  async transitionToCollections(forceFetch?: boolean) {
    if (forceFetch) {
      await this.helpCenterService.forceFetchSite(this.site.id);
    }
    this.router.transitionTo(
      'apps.app.settings.helpcenter.workspace-helpcenter.collections',
      this.site.id,
    );
  }

  @action
  transitionToConfigureAndStyle() {
    this.router.transitionTo(
      'apps.app.settings.helpcenter.workspace-helpcenter.settings',
      this.site.id,
    );
  }

  @action
  onSelectionChange(selection: boolean) {
    if (!selection) {
      // value displayed on the opener shouldn't change instantly
      // only after user has confirmed they want to set the site not live
      this.selectedAttribute = true;
      this.showConfirmSetNotLiveModal = true;
    } else {
      this.showGoToHelpCenter = true;
      this.site.websiteTurnedOn = selection;
      this.selectedAttribute = true;
      this.site.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.helpcenter.banner.live', { helpCenterName: this.site.name }),
      );
    }
  }

  @action
  turnOffHelpCenter() {
    this.showGoToHelpCenter = false;
    this.site.websiteTurnedOn = false;
    this.selectedAttribute = false;
    this.site.save();
    this.showConfirmSetNotLiveModal = false;
    this.notificationsService.notifyConfirmation(
      this.intl.t('new-settings.helpcenter.banner.not-live', { helpCenterName: this.site.name }),
    );
  }

  get selectedLocaleId() {
    return this.helpCenterService.currentlySelectedLocaleId;
  }

  get items() {
    return [
      {
        text: this.intl.t('new-settings.helpcenter.header.dropdown.live'),
        description: this.intl.t('new-settings.helpcenter.header.dropdown.live-description'),
        icon: 'circle-filled',
        value: true,
        component: 'new-settings/helpcenter/site-status-dropdown-item',
      },
      {
        text: this.intl.t('new-settings.helpcenter.header.dropdown.not-live'),
        description: this.intl.t('new-settings.helpcenter.header.dropdown.not-live-description'),
        icon: 'circle-filled',
        value: false,
        component: 'new-settings/helpcenter/site-status-dropdown-item',
      },
    ];
  }

  get determineSelection(): number {
    if (this.args.model.customDomain) {
      return 0;
    } else if (this.site.customDomainUsesSsl) {
      return 2;
    } else {
      return 1;
    }
  }

  get titleToken() {
    if (this.args.selectedTab === 'configure') {
      return this.intl.t('new-settings.helpcenter.header.configure-and-style');
    } else {
      return this.args.model.site?.name ?? '';
    }
  }

  get tabs(): TabItem[] {
    return [];
  }

  get paywallFeatureKey() {
    if (this.site.isDefault) {
      return 'help_center';
    } else if (this.app.showMultiHelpCenterPaywalling) {
      return 'multi_help_center';
    }

    return '';
  }

  @dropTask
  *_saveCollection(collection: $TSFixMe) {
    let metadata = {
      action: 'created',
      place: 'collection_list',
    };
    let type = 'collection';
    try {
      yield collection.saveIfDirty();
      this.trackEducateCollectionOrSectionEvent(collection, metadata);
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('articles.collections.import.notification.error', { errorType: type }),
      );
      collection.rollbackAttributes();
    }
  }

  get shouldUpdateCustomDomain() {
    let protocolChange = this.args.tlsSelectionHelper.hasUnsavedChanges;
    let customDomainChange = !!this.site.changedAttributes()['customDomain'];
    return protocolChange || customDomainChange;
  }

  @dropTask
  *_deleteCustomDomain(): TaskGenerator<void> {
    return yield ajax({
      type: 'POST',
      url: `/ember/custom_domains/clean?app_id=${this.app.id}`,
      data: JSON.stringify({ help_center_id: this.site.id }),
    });
  }

  @action
  async cleanCustomDomainAndSave() {
    if (!this.args.model) {
      return;
    }
    let isSuccessful = true;

    if (this.args.model.isManagedCustomDomainEnabled) {
      try {
        await taskFor(this._deleteCustomDomain).perform();
        this.args.model.customDomain = null;
        isSuccessful = true;
      } catch (exception) {
        this.notificationsService.notifyError(
          this.intl.t('new-settings.helpcenter.settings.error'),
        );
        isSuccessful = false;
      }
    }

    if (isSuccessful) {
      this.trackEducateEvent({
        action: 'delete-success',
        object: 'custom-domain',
        place: 'settings',
      });
      this.saveSite();
    } else {
      this.trackEducateEvent({
        action: 'delete-failure',
        object: 'custom-domain',
        place: 'settings',
      });
    }
  }

  @dropTask
  *_createCustomDomain(url: $TSFixMe): TaskGenerator<void> {
    return yield ajax({
      type: 'POST',
      url: `/ember/custom_domains?app_id=${this.app.id}`,
      data: JSON.stringify({ hostname: url, help_center_id: this.site.id }),
    });
  }

  @action
  async createCustomDomain() {
    let isSuccessful = false;
    let url = this.site.customDomainAndPathPrefix;

    if (!url) {
      this.notificationsService.notifyError(
        this.intl.t('articles.settings.basics.custom-domain.errors.invalid-url'),
      );
      return;
    }
    try {
      let createdCustomDomain = await taskFor(this._createCustomDomain).perform(url);
      this.args.model.customDomain = createdCustomDomain;
      isSuccessful = true;
    } catch (exception) {
      let {
        responseJSON: {
          error: { code: errorCode },
        },
      } = exception.jqXHR;
      let errorMessage = this.intl.t('articles.settings.basics.custom-domain.errors.generic');
      if (errorCode === 0) {
        errorMessage = this.intl.t(
          'articles.settings.basics.custom-domain.errors.duplicated-custom-domain',
        );
      } else if (errorCode === 1) {
        errorMessage = this.intl.t('articles.settings.basics.custom-domain.errors.does-not-exist');
      } else if (errorCode === 2) {
        errorMessage = this.intl.t('articles.settings.basics.custom-domain.errors.generic');
      } else if (errorCode === 3) {
        errorMessage = this.intl.t('articles.settings.basics.custom-domain.errors.invalid-url');
      } else if (errorCode === 4) {
        errorMessage = this.intl.t(
          'articles.settings.basics.custom-domain.errors.too-many-certificates',
        );
      } else if (errorCode === 5) {
        errorMessage = this.intl.t('articles.settings.basics.custom-domain.errors.cname-not-set');
      }
      this.notificationsService.notifyError(errorMessage);
      isSuccessful = false;
    }

    if (isSuccessful) {
      this.trackEducateEvent({
        action: 'create-success',
        object: 'custom-domain',
        place: 'settings',
      });
      this.saveSite();
    } else {
      this.trackEducateEvent({
        action: 'create-failure',
        object: 'custom-domain',
        place: 'settings',
      });
    }
  }

  _getSupportedLocalesDiff(defaultLanguageChange: $TSFixMe) {
    let added = [] as $TSFixMe;
    let removed = [] as $TSFixMe;
    this.site.supportedLocales.map((locale: $TSFixMe) => {
      if (locale.changedAttributes().isSelected) {
        if (locale.isSelected) {
          added.push(locale.localeId);
        } else {
          removed.push(locale.localeId);
        }
      }
    });
    // Cleanup so we don't double-track changes to the default
    if (
      defaultLanguageChange &&
      removed.includes(defaultLanguageChange[0]) &&
      added.includes(defaultLanguageChange[1])
    ) {
      removed = removed.filter((localeId: $TSFixMe) => localeId !== defaultLanguageChange[0]);
      added = added.filter((localeId: $TSFixMe) => localeId !== defaultLanguageChange[1]);
    }
    return { added, removed };
  }

  _trackDomainAndPrefixChangeEvent(domainAndPrefixChange: $TSFixMe) {
    let [oldValue, newValue] = domainAndPrefixChange;
    let action = newValue === '' ? 'deleted' : 'created';
    this.trackEducateEvent({
      action,
      object: 'domainAndPrefix',
      place: 'settings',
      domain_url: action === 'deleted' ? oldValue : newValue,
    });
  }

  _trackHomeUrlChangeEvent(homeUrlChange: $TSFixMe) {
    let [oldUrl, newUrl] = homeUrlChange;
    this.trackEducateEvent({
      action: 'saved',
      object: 'home_url',
      place: 'settings',
      owner: 'educate',
      from: oldUrl,
      to: newUrl,
    });
  }

  _trackDefaultLanguageChangeEvent(languageChange: $TSFixMe) {
    this.trackEducateEvent({
      action: 'saved',
      object: 'default_language',
      place: 'settings',
      owner: 'educate',
      from: languageChange[0],
      to: languageChange[1],
    });
  }

  _trackSupportedLocalesChangeEvent({ added, removed }: $TSFixMe) {
    // We're not tracking changes to the title, just isSelected
    let addedLength = added.length as number;
    let removedLength = removed.length as number;
    if (addedLength + removedLength > 0) {
      this.trackEducateEvent({
        action: 'saved',
        object: 'language',
        place: 'settings',
        owner: 'educate',
        added,
        removed,
      });
    }
  }

  _trackDomainChangeEvent(domainChange: $TSFixMe) {
    let [oldDomain, newDomain] = domainChange;
    let action = newDomain === '' ? 'deleted' : 'created';
    this.trackEducateEvent({
      action,
      object: 'domain',
      place: 'settings',
      domain_url: action === 'deleted' ? oldDomain : newDomain,
    });
  }

  _trackAudienceTargetingChange() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'turned_on',
      object: 'audience_targeting',
    });
  }

  _captureAndDisplayError(response: $TSFixMe) {
    this.handleServerError(response, this.intl.t('articles.settings.switch.notification.error'));
  }

  @action
  async saveSite() {
    this.args.toggleIsSaveRunning();
    let domainChange = this.site.changedAttributes()['customDomain'];
    let domainAndPrefixChange = this.site.changedAttributes()['customDomainAndPathPrefix'];
    let defaultLanguageChange = this.site.changedAttributes()['locale'];
    let supportedLocalesChange = this.site.changedAttributes()['supportedLocales'];
    let supportedLocalesDiff = this._getSupportedLocalesDiff(defaultLanguageChange);
    let homeUrlChange = this.site.changedAttributes()['homeUrl'];
    let previousSegmentedContentTurnedOn = this.site.segmentedContentTurnedOn;
    let ticketsPortalRuleset = this.site.ticketsPortalRuleset;

    let shouldSave = await taskFor(this._saveMedia).perform();
    if (!shouldSave || this.erroredDuringUpload) {
      this.erroredDuringUpload = false;
      return;
    }

    try {
      await taskFor(this._saveSite).perform();
      if (!this.site.validations.isValid) {
        this.args.toggleIsSaveRunning();
        return;
      }
      if (domainChange) {
        this._trackDomainChangeEvent(domainChange);
      }
      if (domainAndPrefixChange) {
        this._trackDomainAndPrefixChangeEvent(domainAndPrefixChange);
      }
      if (defaultLanguageChange) {
        this._trackDefaultLanguageChangeEvent(defaultLanguageChange);
      }
      if (supportedLocalesChange) {
        this._trackSupportedLocalesChangeEvent(supportedLocalesDiff);
      }
      if (homeUrlChange) {
        this._trackHomeUrlChangeEvent(homeUrlChange);
      }
      if (!previousSegmentedContentTurnedOn && this.site.segmentedContentTurnedOn) {
        this._trackAudienceTargetingChange();
      }
      if (this.site.ticketsPortalTurnedOn && !!this.site.ticketsPortalRuleset) {
        this.site.ticketsPortalRuleset.fetchAudiencePreviewTask.perform();
      }
      if (ticketsPortalRuleset?.isNew) {
        ticketsPortalRuleset.deleteRecord();
      }
      this.site.setupDefaultPredicateGroup();
    } catch (err) {
      this._captureAndDisplayError(err);
      this.args.toggleIsSaveRunning();
      return;
    }
    this.args.toggleIsSaveRunning();
    this.transitionToCollections();
  }

  @action
  cancelChanges() {
    this.args.tlsSelectionHelper.reset();
    this.site.rollbackAttributes();
    this.uploadHelper.reset();
    this.transitionToCollections(true);
  }

  get app() {
    return this.appService.app;
  }

  @dropTask
  *doSave() {
    this.showConfirmSetLiveModal = false;
    if (this.shouldUpdateCustomDomain) {
      if (this.args.tlsSelectionHelper.currentSelection > 0) {
        yield this.cleanCustomDomainAndSave();
      } else {
        yield this.createCustomDomain();
      }
    } else {
      this.saveSite();
    }
    this.args.tlsSelectionHelper.initialize(this.args.tlsSelectionHelper.currentSelection);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'set_live',
      place: 'app.article.site.settings',
      context: 'articles.settings.save_and_set_live_button',
      section: 'header.main',
    });
    let stepIdentifier = 'guide_library_foundational_steps_publish_help_center';
    if (this.app.canUseActivationProgressIndicator) {
      yield this.guideLibraryService.markStepCompleted(stepIdentifier);
    }
  }

  @dropTask
  *_saveSite() {
    if (this.site.validations.isValid) {
      yield this.site.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.settings.switch.notification.success'),
      );
      this.helpCenterService.notifyPropertyChange('allSites');
    } else {
      this.site.validations.messages.forEach((message: $TSFixMe) =>
        this.notificationsService.notifyError(message),
      );
    }
  }

  async _saveMediaWithUpdates(mediaHelpers: $TSFixMe) {
    for (let mediaHelper of mediaHelpers) {
      if (mediaHelper.get('hasUpdates')) {
        let mediaCollectionType = mediaHelper.get('collectionCardType');
        let collectionType = this.site.customizationOptions?.collectionCard?.global.imageType;
        if (mediaCollectionType === '' || mediaCollectionType === collectionType) {
          await this._updateMedia(mediaHelper);
        }
      }
    }
  }

  async _updateMedia(mediaHelper: $TSFixMe) {
    let url = mediaHelper.get('url');
    if (url) {
      await this.uploadFromMediaHelper(mediaHelper);
    }
    if (!url && mediaHelper.get('isReset')) {
      await this.deleteMediaHelper(mediaHelper);
    }
  }

  _assetRequestFormData(type: $TSFixMe, formMetadata: $TSFixMe = {}) {
    let formData = new FormData();
    formData.append('asset_type', type);
    formData.append('app_id', this.app.id);
    formData.append('help_center_id', this.site.id);
    let siteSettingsName = formMetadata?.siteSettingsName;
    if (type === 'collection_card') {
      let isGlobal: $TSFixMe = siteSettingsName === 'collectionCardGlobal';
      let index = siteSettingsName.indexOf('-') as number;
      let collectionId = isGlobal ? '' : siteSettingsName.slice(index + 1);
      formData.append('collection_card_is_global', isGlobal);
      formData.append('collection_id', collectionId);
    }
    Object.entries(formMetadata).forEach(([key, value]: $TSFixMe) => {
      if (value) {
        formData.append(key, value);
      }
    });
    return formData;
  }

  _storeUpdatedSite(mediaType: $TSFixMe, updatedSiteResponse: $TSFixMe) {
    if (mediaType === 'collection_card') {
      return;
    }
    this.store.pushPayload({
      'help-center-sites': [updatedSiteResponse],
    });
  }

  async deleteAsset(type: $TSFixMe, formMetadata: $TSFixMe) {
    let formData = this._assetRequestFormData(type, formMetadata);
    let response = await ajax({
      type: 'DELETE',
      url: '/ember/help_center_sites/delete_asset.json',
      data: formData,
      contentType: false,
      processData: false,
    });
    this._storeUpdatedSite(type, response);
  }

  async deleteMediaHelper(mediaHelper: $TSFixMe, formMetadata?: $TSFixMe) {
    let siteSettingsName = mediaHelper.get('siteSettingsName');
    let type = mediaHelper.get('type');
    try {
      await this.deleteAsset(type, {
        siteSettingsName,
        ...formMetadata,
      });
      mediaHelper.reset();
    } catch (response) {
      this.handleServerError(response, this.intl.t('articles.settings.switch.notification.error'));
      mediaHelper.setProperties({
        isSaved: false,
        isReset: true,
      });
    }
  }

  async uploadAsset(file: $TSFixMe, type: $TSFixMe, formMetadata: $TSFixMe) {
    let formData = this._assetRequestFormData(type, formMetadata);
    formData.append('image', file);
    let response = await ajax({
      type: 'POST',
      url: '/ember/help_center_sites/upload_asset.json',
      data: formData,
      contentType: false,
      processData: false,
    });
    this._storeUpdatedSite(type, response);
  }

  async uploadFromMediaHelper(mediaHelper: $TSFixMe, formMetadata = {}) {
    let siteSettingsName = mediaHelper.get('siteSettingsName');
    let file = mediaHelper.get('file');
    let type = mediaHelper.get('type');
    try {
      await this.uploadAsset(file, type, {
        siteSettingsName,
        ...formMetadata,
      });
      mediaHelper.setProperties({
        isSaved: true,
        isReset: false,
      });
    } catch (response) {
      this.handleServerError(response, this.intl.t('articles.settings.switch.notification.error'));
      mediaHelper.setProperties({
        isSaved: false,
        isReset: true,
      });
      this.erroredDuringUpload = true;
    }
  }

  async saveCustomFontFaces() {
    let allFontFaces = this.site.customizationOptions?.global?.font?.customFontFaces;
    if (!allFontFaces) {
      return;
    }
    let fontFacesToUpload = allFontFaces.filter(
      (fontFace: $TSFixMe) => fontFace.mediaHelper && fontFace.mediaHelper.hasUpdates,
    );
    for (let fontFace of fontFacesToUpload) {
      let mediaHelper = fontFace.mediaHelper;
      let formMetadata = fontFace.serialize();
      await this.uploadFromMediaHelper(mediaHelper, formMetadata);
    }
  }

  resetCardOptionsSnapshot() {
    this.site.customizationOptions.cardOptionsSnapshots = {};
  }

  @dropTask
  *_saveMedia() {
    if (this.site.hasMissingImages()) {
      this.notificationsService.notifyError(
        this.intl.t('articles.settings.redesign.validations.missing-image'),
      );
      return false;
    }

    let mediaHelpers = [
      this.uploadHelper.header,
      this.uploadHelper.logo,
      this.uploadHelper.footerLogo,
      this.uploadHelper.favicon,
      this.uploadHelper.social,
      this.uploadHelper.contentBlock,
    ];
    mediaHelpers = mediaHelpers.concat(this.uploadHelper.collection_card);

    yield this._saveMediaWithUpdates(mediaHelpers);
    yield this.saveCustomFontFaces();

    this.resetCardOptionsSnapshot();
    return true;
  }

  get saveIsRunning() {
    return taskFor(this._saveSite).isRunning || taskFor(this._saveMedia).isRunning;
  }

  set saveIsRunning(_value) {
    // noop
  }

  _transitionToCollection(collection: $TSFixMe) {
    this.router.transitionTo('apps.app.articles.site.collections.collection', collection.get('id'));
  }

  trackEducateCollectionOrSectionEvent(sectionOrCollection: $TSFixMe, metadata: $TSFixMe = {}) {
    metadata.owner = 'educate';
    metadata.object = sectionOrCollection.get('type');
    if (sectionOrCollection.get('isCollection')) {
      metadata.collection_id = sectionOrCollection.get('id');
    } else {
      metadata.section_id = sectionOrCollection.get('id');
      metadata.collection_id = sectionOrCollection.get('parent.id');
    }
    this.intercomEventService.trackEvent('educate-event', metadata);
  }

  trackEducateEvent(metadata: $TSFixMe) {
    metadata.owner = 'educate';
    this.intercomEventService.trackEvent('educate-event', metadata);
  }

  handleServerError(response: $TSFixMe, message: $TSFixMe) {
    let xhr = response.jqXHR;
    if (xhr && xhr.responseJSON && xhr.responseJSON.message) {
      let errorMessage = xhr.responseJSON.message;
      let displayedErrorMessage = `${message}: ${errorMessage}.`;

      if (
        xhr.statusText === 'Conflict' &&
        xhr.responseJSON.meta &&
        xhr.responseJSON.meta.conflict_resource
      ) {
        console.error(
          'Articles existing resource conflict',
          xhr.responseJSON.meta.conflict_resource,
        );
      }

      this.notificationsService.notifyResponseError(
        response,
        { default: displayedErrorMessage },
        { duration: ENV.APP.educateErrorNotificationTimeout },
      );
    } else {
      this.notificationsService.notifyResponseError(response, { default: `${message}.` });
    }
  }

  get site() {
    return this.helpCenterService.site;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpcenter::Main': typeof HelpcenterMain;
    'new-settings/helpcenter/main': typeof HelpcenterMain;
  }
}
