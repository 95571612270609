/* import __COLOCATED_TEMPLATE__ from './checklist.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import type RouterService from '@ember/routing/router-service';
import { post } from 'embercom/lib/ajax';
import {
  FLOATING_WIDGET_DISMISSAL_KEY,
  FLOATING_WIDGET_REMINDER_LOCALSTORAGE_KEY,
} from 'embercom/objects/onboarding/checklist/step';
import { modifier } from 'ember-modifier';
import routeRegexes from 'embercom/lib/route-regexes';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';
import type Nexus from 'embercom/services/nexus';
import { type NexusEvent } from 'embercom/services/nexus';
import type Transition from '@ember/routing/transition';
import type InboxState from 'embercom/services/inbox-state';
import checkEmailIsPersonal from 'embercom/utils/check-email-domain';
import type Session from 'embercom/services/session';

export interface ChecklistArgs {
  appId: string;
}

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
const TWO_HOURS_IN_MS = 2 * 60 * 60 * 1000;

export default class Checklist extends Component<ChecklistArgs> {
  @service declare router: RouterService;
  @service declare realTimeEventService: any;
  @service declare customerService: $TSFixMe;
  @service declare greatGuidanceService: GreatGuidanceService;
  @service declare intercomEventService: any;
  @service declare nexus: Nexus;
  @service declare navbarCollapsingService: $TSFixMe;
  @service declare inboxState: InboxState;
  @service permissionsService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare session: Session;

  @tracked animationPopped = false;
  @tracked animateStepId = '';
  @tracked showModal = false;
  @tracked isWorkEmail = false;

  @tracked timeout: ReturnType<typeof setTimeout> | null = null;

  boundRouteChangeHandler = this.routeChangeHandler.bind(this);

  constructor(owner: unknown, args: ChecklistArgs) {
    super(owner, args);

    if (this.customerHasActiveSubscription) {
      let reminderTimeline = JSON.parse(
        localStorage.getItem(FLOATING_WIDGET_REMINDER_LOCALSTORAGE_KEY) || '[]',
      );
      if (reminderTimeline.length && reminderTimeline.length < 3) {
        this.greatGuidanceService.hideFloatingWidget = !(
          reminderTimeline.length &&
          this.isPastReminderTimeInterval(
            parseInt(reminderTimeline[reminderTimeline.length - 1], 10),
          )
        );
        if (!this.greatGuidanceService.hideFloatingWidget) {
          this.greatGuidanceService.fetchSteps(this.args.appId);
          this.addNexusEventListeners();
        }
      } else {
        taskFor(this.showWidgetIfNotDismissed).perform();
      }
    } else {
      taskFor(this.showWidgetIfNotDismissed).perform();
    }

    this.router.on('routeDidChange', this.boundRouteChangeHandler);
    this.setIsWorkEmail();
  }

  get adminEmail() {
    if (this.onInboxRoute) {
      return this.session.teammate.email;
    }
    return this.appService.app.currentAdmin.email;
  }

  get hasAccessToProductIa() {
    // Add a check for access to product IA via Opt in flow in both inbox route and other routes
    if (this.onInboxRoute) {
      return this.session.workspace.features.includes('team-product-exploration-product-ia');
    }
    return this.appService.app.canSeeUpdatedProductIA;
  }

  get elevateWidgetForPage() {
    return (
      this.hasAccessToProductIa &&
      (this.onInboxRoute || this.router.currentRouteName?.match(routeRegexes.reports.all))
    );
  }

  get appId() {
    if (this.onInboxRoute) {
      return this.session.workspace.id;
    }
    return this.appService.app.id;
  }

  get isAppOnPricing5() {
    if (this.onInboxRoute) {
      return this.session.workspace.onPricing5;
    }
    return this.appService.app.onPricing5;
  }

  async setIsWorkEmail() {
    let personalEmailDomain = await checkEmailIsPersonal(this.adminEmail?.split('@')[1]);
    this.isWorkEmail = !personalEmailDomain;
  }

  @action
  async onCTASuccess(stepIdentifier: string) {
    if (stepIdentifier === 'great_guidance_invite_teammates' && this.isAppOnPricing5) {
      if (!this.permissionsService.currentAdminCan('can_manage_teammates')) {
        this.permissionsService.loadAllAdminsAndShowPermissionRequestModal('can_manage_teammates');
      } else {
        this.showModal = true;
      }
    }
  }

  get customerHasActiveSubscription() {
    return (
      !this.customerService.customer?.inCardlessTrial &&
      this.customerService.customer?.hasActiveSubscription
    );
  }

  get onInboxRoute() {
    return (
      this.router.currentRouteName?.match(routeRegexes.inbox) ||
      this.router.currentRouteName?.match(routeRegexes.inbox2)
    );
  }

  willDestroy() {
    super.willDestroy();
    this.removeNexusEventListeners();
    this.router.off('routeDidChange', this.boundRouteChangeHandler);
  }

  addNexusEventListeners() {
    if (this.onInboxRoute) {
      this.nexus.subscribeTopics(['mark-step-as-completed']);
      this.nexus.addListener('MarkStepAsCompleted', this.markStepCompletedInbox);
    } else {
      this.realTimeEventService.subscribeTopics(['mark-step-as-completed']);
      this.realTimeEventService.on('MarkStepAsCompleted', this, 'markStepCompleted');
    }
  }

  removeNexusEventListeners() {
    if (this.onInboxRoute) {
      this.nexus.unsubscribeTopics(['mark-step-as-completed']);
      this.nexus.removeListener('MarkStepAsCompleted', this.markStepCompleted);
    } else {
      this.realTimeEventService.unsubscribeTopics(['mark-step-as-completed']);
      this.realTimeEventService.off('MarkStepAsCompleted', this, 'markStepCompleted');
    }
  }

  isPastReminderTimeInterval(lastAccessed: number) {
    let currentTime = Date.now();
    let timeDifference = currentTime - lastAccessed;

    return timeDifference >= ONE_DAY_IN_MS;
  }

  animateOnRender = modifier(
    (checklistContainer: HTMLDivElement) => {
      let endedFn = () => {
        this.animationPopped = true;
        checklistContainer.classList.add('rendered');
        checklistContainer.classList.remove('nudge');
      };

      let nudgeTimeout: $TSFixMe;

      let nudge = () => {
        checklistContainer.classList.add('nudge');
        setTimeout(() => checklistContainer.classList.remove('nudge'), 2 * 1000);
        nudgeTimeout = setTimeout(nudge, TWO_HOURS_IN_MS);
      };

      let clickFn = () => {
        clearTimeout(nudgeTimeout);
        nudgeTimeout = setTimeout(nudge, TWO_HOURS_IN_MS);
      };

      nudgeTimeout = setTimeout(nudge, TWO_HOURS_IN_MS);

      let slideUp = () => {
        if (!this.greatGuidanceService.isFloatingWidgetExpanded) {
          checklistContainer.classList.add('slide-up');
        }
      };

      let slideDown = () => {
        if (!this.greatGuidanceService.isFloatingWidgetExpanded) {
          checklistContainer.classList.remove('slide-up');
        }
      };

      checklistContainer.addEventListener('animationend', endedFn, { once: true });
      checklistContainer.addEventListener('click', clickFn);
      checklistContainer.addEventListener('mouseenter', slideUp);
      checklistContainer.addEventListener('mouseleave', slideDown);

      return function () {
        checklistContainer.removeEventListener('animationend', endedFn);
        checklistContainer.removeEventListener('click', clickFn);
        checklistContainer.removeEventListener('mouseenter', slideUp);
        checklistContainer.removeEventListener('mouseleave', slideDown);
      };
    },
    { eager: true },
  );

  get stepsLoaded() {
    return this.greatGuidanceService.steps && this.greatGuidanceService.steps.length > 0;
  }

  get totalSteps() {
    return this.greatGuidanceService.steps.length;
  }

  get completedSteps() {
    return this.greatGuidanceService.steps.filter((step) => step.state === 'completed').length;
  }

  get showDismissOrReminderButton() {
    return this.customerHasActiveSubscription;
  }

  @action
  expandOrNavigateToOnboarding() {
    this._expandOrNavigateToOnboarding();
  }

  @action
  async closeQuickInviteModal() {
    this.showModal = false;
    await this.greatGuidanceService.fetchSteps(this.appId);
  }

  @action
  collapse() {
    this.timeout && clearTimeout(this.timeout);
    let widgetContainer = document.querySelector('#onboarding-floating-widget-container');
    if (widgetContainer) {
      window.requestAnimationFrame(() => {
        widgetContainer?.classList.add('collapse-spring-animation');
        this.timeout = setTimeout(() => {
          widgetContainer?.classList.remove('collapse-spring-animation');
        }, 400);
      });
    }
    this.greatGuidanceService.isFloatingWidgetExpanded = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'getting_started_homepage',
      object: `onboarding-widget.collapsed`,
      section: 'onboarding.getting-started.widget',
      place: 'onboarding.getting-started',
    });
  }

  @action
  hideChecklist() {
    this.greatGuidanceService.hideFloatingWidget = true;
  }

  _expandOrNavigateToOnboarding() {
    if (this.completedSteps === this.totalSteps) {
      this.dismissWidget();
      this.router.transitionTo('apps.app.getting-started', this.args.appId);
    } else {
      if (
        (this.onInboxRoute && !this.inboxState.isInboxListPinned) ||
        (!this.onInboxRoute && this.navbarCollapsingService.collapsed)
      ) {
        return;
      }
      this.greatGuidanceService.isFloatingWidgetExpanded = true;
      this.timeout && clearTimeout(this.timeout);
      let widgetContainer = document.querySelector('#onboarding-floating-widget-container');
      if (widgetContainer) {
        window.requestAnimationFrame(() => {
          widgetContainer?.classList.add('expand-spring-animation');
          this.timeout = setTimeout(() => {
            widgetContainer?.classList.remove('expand-spring-animation');
          }, 400);
        });
      }
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        context: 'getting_started_homepage',
        object: `onboarding-widget.expanded`,
        section: 'onboarding.getting-started.widget',
        place: 'onboarding.getting-started',
      });
    }
  }

  async dismissWidget() {
    let params = {
      page_key: FLOATING_WIDGET_DISMISSAL_KEY,
    };
    try {
      this.hideChecklist();
      await post(`/ember/admin_pageviews?app_id=${this.args.appId}`, params);
    } catch (e) {
      console.error('Failed to save dismissal', e);
    }
  }

  @dropTask
  *showWidgetIfNotDismissed(): Generator<any, void, any> {
    yield this.greatGuidanceService.fetchSteps(this.args.appId);

    if (!this.greatGuidanceService.hideFloatingWidget) {
      this.addNexusEventListeners();
    }
  }

  @action
  markStepCompletedInbox(event: NexusEvent) {
    this.markStepCompleted({ step_identifier: event.eventData.step_identifier });
  }

  markStepCompleted(event: any) {
    this._expandOrNavigateToOnboarding();
    let step = this.greatGuidanceService.steps.find(
      (step: any) => step.identifier === event.step_identifier,
    );
    if (step) {
      step.state = 'completed';
      this.greatGuidanceService.nextStepId = undefined;
      this.greatGuidanceService.currentStepId = this.greatGuidanceService.steps.find(
        (step: any) => step.state === 'available',
      )?.identifier;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'completed',
        context: 'getting_started_homepage',
        object: `step-completed`,
        step_identifier: `${step.identifier}`,
        section: 'onboarding.getting-started',
        place: 'onboarding.getting-started',
      });
      this.animateStepId = step.identifier;
      setTimeout(() => {
        this.animateStepId = '';
        if (!this.greatGuidanceService.currentStepId) {
          this.greatGuidanceService.isFloatingWidgetExpanded = false;
        }
      }, 4000);
    }
  }

  private routeChangeHandler(transition: Transition) {
    if (
      transition.from?.name === 'apps.app.knowledge-hub.edit' ||
      transition.from?.name.match(/apps\.app\.outbound\.[a-zA-Z0-9_-]*\.edit/) ||
      transition.from?.name.match(/apps\.app\.settings.workspace.teammates.invite.permissions/)
    ) {
      return;
    }

    this.greatGuidanceService.isFloatingWidgetExpanded = false;
    this.animateStepId = '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::FloatingWidget::Checklist': typeof Checklist;
    'onboarding/floating-widget/checklist': typeof Checklist;
  }
}
