/* import __COLOCATED_TEMPLATE__ from './optimize.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { helper } from '@ember/component/helper';
interface Arguments {
  liveFinWorkflowsCount: number;
  liveProfilesWithAiAnswersEnabled: boolean;
  finSetupRulesetStateIsLive: boolean;
  latestQuestionExtractionRunId: number;
  reloadModel: () => void;
}

interface QuestionGroup {
  id: number;
  subject: string;
  locale: string;
  stats: {
    totalCount: number;
    unansweredCount: number;
    routedToTeamCount: number;
    abandonedCount: number;
  };
}

interface Signature {
  Element: HTMLDivElement;
  Args: Arguments;
}

export default class Optimize extends Component<Signature> {
  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };
  @service notificationsService: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  @service declare store: Store;
  @service declare appService: $TSFixMe;

  @tracked questionGroups: QuestionGroup[] = [];
  @tracked locale: string | null;
  @tracked selectableLocales: Array<string>;
  @tracked filteredQuestionGroups: QuestionGroup[] = [];
  @tracked sideDrawerOpen = false;
  @tracked selectedQuestionGroupId?: string;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    this.locale = null;
    this.selectableLocales = [];
    this.intercomEventService.trackEvent('viewed-ai-chatbot-optimize');
    taskFor(this.fetchQuestionGroups).perform();
  }

  learnMoreUrl = 'https://www.intercom.com/help/en/articles/8890980-how-to-optimize-fin';

  language = helper(([locale]: string[]) => {
    return this.intl.languageNameFromCode(locale);
  });

  get isLoading() {
    return taskFor(this.fetchQuestionGroups).isRunning;
  }

  get columns() {
    let columns = [
      {
        label: this.intl.t('operator.fin.setup.optimize.clusters-table.question-group'),
        valuePath: 'title',
        isSortable: true,
        width: '70%',
      },
      {
        label: this.intl.t('operator.fin.setup.optimize.clusters-table.language'),
        valuePath: 'locale',
        isSortable: true,
      },
      {
        label: this.intl.t('operator.fin.setup.optimize.clusters-table.volume'),
        valuePath: 'stats.totalCount',
        type: 'number',
        isSortable: true,
      },
      {
        label: this.intl.t('operator.fin.setup.optimize.clusters-table.abandoned'),
        valuePath: 'stats.abandonedPercentage',
        type: 'number',
        isSortable: true,
      },
      {
        label: this.intl.t('operator.fin.setup.optimize.clusters-table.routed-to-team'),
        valuePath: 'stats.routedToTeamPercentage',
        type: 'number',
        isSortable: true,
      },
    ];

    return columns;
  }

  get locales() {
    return [
      {
        text: this.intl.t('ai-content-library.filters.locales.all'),
        value: null,
      },
      ...this.selectableLocales.map((locale: string) => ({
        text: this.intl.languageNameFromCode(locale),
        value: locale,
      })),
    ];
  }

  get selectedLocaleName() {
    let locale = this.locales.find((locale) => locale.value === this.locale);
    return !locale
      ? this.intl.t('ai-content-library.filters.locales.all-languages')
      : this.intl.t('ai-content-library.filters.locales.language-is', {
          language: locale.text,
        });
  }

  get hasQuestionGroups() {
    return this.questionGroups.length > 0;
  }

  get hasFinLive() {
    return (
      this.args.liveFinWorkflowsCount > 0 ||
      this.args.liveProfilesWithAiAnswersEnabled ||
      this.args.finSetupRulesetStateIsLive
    );
  }

  getLocales() {
    return Array.from(new Set(this.questionGroups.map((questionGroup) => questionGroup.locale)));
  }

  @action
  searchByLocale(locale: string) {
    this.locale = locale;
    this.filteredQuestionGroups = this.questionGroups.filter((questionGroup) => {
      return questionGroup.locale === this.locale;
    });
  }

  @action
  closeSideDrawer() {
    this.sideDrawerOpen = false;
  }

  @action
  openSideDrawer(id: string) {
    this.selectedQuestionGroupId = id;
    this.sideDrawerOpen = true;
    this.trackAnalyticsEvent('clicked', 'question_group_link');
  }

  @task({ restartable: true })
  *fetchQuestionGroups() {
    try {
      this.questionGroups = yield this.store.findAll('ai-agent/question-group');
      this.selectableLocales = this.getLocales();
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.optimize.error-response'),
      );
    }
  }

  get lastUpdatedDate() {
    let timestampInMilliseconds = this.args.latestQuestionExtractionRunId * 1000;
    return this.intl.formatDate(timestampInMilliseconds, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }

  private trackAnalyticsEvent(action: string, object: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      place: 'overview_optimize',
      section: 'ai_chatbot',
      question_group_id: this.selectedQuestionGroupId,
    });
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Optimize': typeof Optimize;
  }
}
