/* import __COLOCATED_TEMPLATE__ from './content-sources.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { isEmpty } from '@ember/utils';
import { EntityType } from 'embercom/models/data/entity-types';
import { type ConversationContentSettings } from 'embercom/controllers/apps/app/fin-ai-agent/setup';
import { localCopy } from 'tracked-toolbox';
import { post } from 'embercom/lib/ajax';
import type ContentImportService from 'embercom/services/content-import-service';
import { captureException } from 'embercom/lib/sentry';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type RouterService from '@ember/routing/router-service';

export interface ContentSourcesArgs {
  liveProfilesWithAiAnswersEnabled: boolean;
  liveProfilesWithCustomAnswersEnabled: boolean;
  allArticlesCount: number;
  finUsableArticlesCount: number;
  allCustomAnswersCount: number;
  finUsableCustomAnswersCount: number;
  finUsableExternalContentCount: number;
  allSnippetsCount: number;
  finUsableSnippetsCount: number;
  allActionsCount: number;
  finUsableActionsCount: number;
  allFilesCount: number;
  finUsableFilesCount: number;
  helpCenterSitesCount: number;
  conversationContentSettings: ConversationContentSettings;
  reloadModel: any;
  openSectionId: string;
  setOpenSectionId: (sectionId: string) => void;
  hasLiveFinWorkflow: boolean;
  hasContentReadyForFin: boolean;
  hideUsedByFinStamp?: boolean;
}

export type ConversationContentAvailabilitySetting = 'available' | 'not-available';

type FinContentStatusType =
  | 'content-empty'
  | 'content-importing'
  | 'content-importing-completed'
  | 'content-ready';

export default class ContentSources extends Component<ContentSourcesArgs> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare contentImportService: ContentImportService;
  @service declare permissionsService: $TSFixMe;
  @service intercomEventService: any;
  @service declare notificationsService: any;
  @service declare store: Store;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare router: RouterService;

  @tracked openSectionId: string = this.defaultOpenSectionId;
  @tracked showAddSourceModal = false;
  @tracked showFileUploadModal = false;

  @localCopy('args.conversationContentSettings.enabled')
  declare conversationContentEnabled: boolean;
  @tracked showConversationContentEnableModal = false;
  @localCopy('args.conversationContentSettings.immediatelyAvailableToFin')
  declare conversationContentImmediatelyAvailableToFin: boolean;
  @localCopy('args.conversationContentSettings.adminIDs')
  declare conversationContentAdminIDs: number[];

  externalContentEntityType: EntityType = EntityType.ExternalContent;
  snippetEntityType: EntityType = EntityType.ContentSnippet;
  articleEntityType: EntityType = EntityType.ArticleContent;
  fileEntityType: EntityType = EntityType.FileSourceContent;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.contentImportService.subscribeToContentImportRunStatusUpdates();
    this.contentImportService.fetchContentImportSources();
    this.contentImportService.assignCompletionCallback(this.args.reloadModel.bind(this));
  }

  get app() {
    return this.appService.app;
  }

  get contentImportRunIsInProgress(): boolean {
    return this.contentImportService.inProgressRunExists;
  }

  get contentImportRunisCompleted(): boolean {
    if (this.contentImportService.successfulRuns.length === 0) {
      return false;
    }

    return true;
  }

  get externalContentTitle() {
    return this.intl.t('operator.fin.setup.content-sources.webpages.title');
  }

  get contentFolderId() {
    return this.args.conversationContentSettings.contentFolderId;
  }

  get canUseConversationExtractionExperiment() {
    return this.appService.app.canUseConversationExtractionExperiment;
  }

  goToContentList(entityType: EntityType) {
    this.knowledgeHubService.goToContent(entityType);
  }

  @action createArticle() {
    this.knowledgeHubService.createArticle();
  }

  @action goToArticles() {
    this.goToContentList(EntityType.ArticleContent);
  }

  @action createSnippet() {
    this.knowledgeHubService.createSnippet();
  }

  @action goToSnippets() {
    this.goToContentList(EntityType.ContentSnippet);
  }

  @action goToActions() {
    this.router.transitionTo('apps.app.settings.app-settings.custom-actions.index');
  }

  @action goToExternalContent() {
    this.goToContentList(EntityType.ExternalContent);
  }

  @action goToFiles() {
    this.goToContentList(EntityType.FileSourceContent);
  }

  @action goToConversationContentFolder() {
    if (this.canUseConversationExtractionExperiment && this.contentFolderId) {
      this.knowledgeHubService.goToFolder(this.contentFolderId);
    }
  }

  get finContentStatus(): FinContentStatusType {
    if (this.contentImportRunIsInProgress) {
      return 'content-importing';
    } else if (this.contentImportRunisCompleted) {
      return 'content-importing-completed';
    } else if (!this.args.hasContentReadyForFin) {
      return 'content-empty';
    } else {
      return 'content-ready';
    }
  }

  get bannerContent() {
    let contentBannerRoute = 'operator.fin.setup.tabs.add-content.banner';

    switch (this.finContentStatus) {
      case 'content-empty':
        return {
          heading: `${contentBannerRoute}.content-empty.heading`,
          description: `${contentBannerRoute}.content-empty.description`,
          dismissalKey: `discovery-banner-fin-${this.finContentStatus}`,
        };
      case 'content-importing':
        return {
          heading: `${contentBannerRoute}.content-importing.heading`,
          description: `${contentBannerRoute}.content-importing.description`,
          dismissalKey: `discovery-banner-fin-${this.finContentStatus}`,
        };
      case 'content-importing-completed':
        return {
          heading: `${contentBannerRoute}.content-importing-completed.heading`,
          description: `${contentBannerRoute}.content-importing-completed.description`,
          dismissalKey: `discovery-banner-fin-${this.finContentStatus}`,
        };
      case 'content-ready':
        return { heading: '', description: '', dismissalKey: '' };
    }
  }

  get defaultOpenSectionId(): string {
    if (!isEmpty(this.args.openSectionId)) {
      return this.args.openSectionId;
    } else if (this.externalContentSourcesExist) {
      return 'external-content';
    }
    return '';
  }

  get liveStamp() {
    return {
      text: this.intl.t('operator.fin.setup.content-sources.live-label'),
      color: 'green',
    } as const;
  }

  get readyStamp() {
    return {
      text: this.intl.t('operator.fin.setup.content-sources.ready-label'),
      color: 'sky',
    } as const;
  }

  get externalContentReadyForFin(): boolean {
    return this.args.finUsableExternalContentCount > 0;
  }

  get externalContentSourcesExist(): boolean {
    let sources = this.contentImportService.contentImportSources ?? [];
    return sources.length > 0;
  }

  get externalContentSourcesCount() {
    let sources = this.contentImportService.contentImportSources ?? [];
    return sources.length;
  }

  get articlesReadyForFin(): boolean {
    return this.args.finUsableArticlesCount > 0;
  }

  get customAnswersReadyForFin(): boolean {
    return this.args.finUsableCustomAnswersCount > 0;
  }

  get snippetsReadyForFin(): boolean {
    return this.args.finUsableSnippetsCount > 0;
  }

  get actionsReadyForFin(): boolean {
    return this.args.finUsableActionsCount > 0;
  }

  get conversationContentAdminsLabel() {
    if (this.conversationContentAdminIDs.length > 0) {
      return this.intl.t(
        'operator.fin.setup.content-sources.conversation-content.admin-settings.label.selected',
        { num: this.conversationContentAdminIDs.length },
      );
    } else {
      return this.intl.t(
        'operator.fin.setup.content-sources.conversation-content.admin-settings.label.blank',
      );
    }
  }

  get showConversationContentAdminsSettings() {
    return this.conversationContentEnabled;
  }

  get conversationContentAdmins() {
    let selectedAdmins = this.conversationContentAdminIDs;
    let requiresInboxSeatAccess = this.appService.app.requiresInboxSeatAccess;

    let admins = requiresInboxSeatAccess
      ? this.appService.app.adminsWithInboxAccess
      : this.appService.app.humanAdmins;

    return admins.map((admin: any) => ({
      text: admin.name,
      value: Number(admin.id),
      isSelected: selectedAdmins.includes(Number(admin.id)),
    }));
  }

  get numberOfPendingSnippetsToReview(): number {
    let reviewSummary = this.contentImportService.aiContentReviewSummary as any;
    return reviewSummary.pending;
  }

  @action conversationContentAdminsChanged(adminIDs: number[]) {
    this.conversationContentAdminIDs = adminIDs.map((id) => Number(id));
  }

  @action clearAllConversationContentAdmins() {
    this.conversationContentAdminIDs = [];
  }

  @action async submitConversationContentAdmins() {
    try {
      await post('/ember/inbox/fin_conversation_content/update_admins', {
        app_id: this.appService.app.id,
        admin_ids: this.conversationContentAdminIDs,
      });
      this.trackAnalyticsEvent('update_admins', 'inbox_fin_question_answers', {
        admin_ids: this.conversationContentAdminIDs,
      });
    } catch (e) {
      this.conversationContentAdminIDs = this.args.conversationContentSettings.adminIDs;
      captureException(e);
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.content-sources.conversation-content.update-admins-failed'),
      );
    }
  }

  @action openSection(id: string) {
    this.args.setOpenSectionId(id);
    this.openSectionId = id;
    this.trackAnalyticsEvent('opened', 'panel', { panel_id: id });
  }

  get htmlConversationContentAvailability(): ConversationContentAvailabilitySetting {
    return this.conversationContentImmediatelyAvailableToFin ? 'available' : 'not-available';
  }

  set htmlConversationContentAvailability(value: ConversationContentAvailabilitySetting) {
    this.conversationContentImmediatelyAvailableToFin = value === 'available';
  }

  @action async onEnableImmediatelyAvailableToFin() {
    this.updateSettings(true);
    try {
      await post('/ember/inbox/fin_conversation_content/enable_immediately_available_to_fin', {
        app_id: this.appService.app.id,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'operator.fin.setup.content-sources.conversation-content.immediately-available-toast-success',
        ),
      );
    } catch (error) {
      this.updateSettings(false);
      captureException(error);
      this.notificationsService.notifyError(
        this.intl.t(
          'operator.fin.setup.content-sources.conversation-content.immediately-available-toast-failure',
        ),
      );
      return;
    }
  }

  @action async onDisableImmediatelyAvailableToFin() {
    this.updateSettings(false);
    try {
      await post('/ember/inbox/fin_conversation_content/disable_immediately_available_to_fin', {
        app_id: this.appService.app.id,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'operator.fin.setup.content-sources.conversation-content.not-immediately-available-toast-success',
        ),
      );
    } catch (error) {
      this.updateSettings(true);
      captureException(error);
      this.notificationsService.notifyError(
        this.intl.t(
          'operator.fin.setup.content-sources.conversation-content.not-immediately-available-toast-failure',
        ),
      );
      return;
    }
  }

  private updateSettings(immediatelyAvailableToFin: boolean) {
    this.conversationContentImmediatelyAvailableToFin = immediatelyAvailableToFin;
    if (this.contentImportService.finConversationContentSettings) {
      let finConversationContentSettings = this.contentImportService.finConversationContentSettings;
      finConversationContentSettings.immediatelyAvailableToFin = immediatelyAvailableToFin;
      this.contentImportService.finConversationContentSettings = finConversationContentSettings;
    }
  }

  @action toggleConversationContent() {
    if (this.conversationContentEnabled) {
      this.disableConversationContent();
    } else {
      this.showConversationContentEnableModal = true;
    }
  }

  @action async enableConversationContent() {
    try {
      await post('/ember/inbox/fin_conversation_content/enable', {
        app_id: this.appService.app.id,
      });
      this.showConversationContentEnableModal = false;
      this.conversationContentEnabled = true;
      this.trackAnalyticsEvent('toggle', 'inbox_fin_question_answers', { enabled: true });
      if (this.contentImportService.finConversationContentSettings) {
        this.contentImportService.finConversationContentSettings.enabled = true;
      }
    } catch (e) {
      this.conversationContentEnabled = false;
      captureException(e);
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.content-sources.conversation-content.enable-failed'),
      );
    }
  }

  @action async disableConversationContent() {
    try {
      await post('/ember/inbox/fin_conversation_content/disable', {
        app_id: this.appService.app.id,
      });
      this.conversationContentEnabled = false;
      this.trackAnalyticsEvent('toggle', 'inbox_fin_question_answers', { enabled: false });
      if (this.contentImportService.finConversationContentSettings) {
        this.contentImportService.finConversationContentSettings.enabled = false;
      }
    } catch (e) {
      this.conversationContentEnabled = true;
      captureException(e);
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.content-sources.conversation-content.disable-failed'),
      );
    }
  }

  @action async openAddSourceModal() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    this.showAddSourceModal = true;
  }

  @action async openFileUploadModal() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    this.showFileUploadModal = true;
  }

  private trackAnalyticsEvent(action: string, object: string, metadata?: any): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'fin_setup',
      ...metadata,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::ContentSources': typeof ContentSources;
  }
}
